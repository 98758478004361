/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React from 'react'
import { graphql } from 'gatsby'

// Components
import SEO from 'components/shared/SEO'
import Layout from 'components/layout/Layout'
import ParseContent from 'components/shared/ParseContent'
import GravityForm from 'components/shared/GravityForm'
import ImageBackground from 'components/shared/ImageBackground'

// Third Party
import styled from 'styled-components'
import { FaFacebookF, FaLinkedinIn } from 'react-icons/fa'

const PageTemplate = ({
  data: {
    page: { path, yoast_meta: yoast, acf },
  },
}) => {
  return (
    <Layout activePage={path}>
      <SEO yoast={yoast} path={path} article />

      <Hero src={acf.banner.image} style={{ backgroundSize: '100%', backgroundPosition: 'top' }} aboveFold>
        <div className="container">
          <div className="col-lg-6 pt-5 pb-4">
            <Heading>CONTACT</Heading>
          </div>
        </div>
        <RedLine />
        <div className="container py-5 d-flex flex-wrap">
          <div className="col-lg-6">
            <Content content={acf.info.description} />
            <div className="d-flex mb-5">
              <CircleButton href={acf.info.facebook} target="_blank">
                <FaFacebookF color="black" size={28} />
              </CircleButton>
              <CircleButton href={acf.info.instagram} target="_blank">
                <FaLinkedinIn color="black" size={28} />
              </CircleButton>
            </div>
          </div>
          <div className="col-lg-6 px-0">
            <Form>
              <h3 className="mb-4">NEEM CONTACT OP</h3>
             <GravityForm id={1} />
            </Form>
          </div>
        </div>
      </Hero>
      <div className="container">
        <div className="col-lg-6 pt-5 pb-4">
          <Heading className="text-dark">LOCATIE </Heading>
        </div>
      </div>
      <RedLine className="mb-3" />

      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2459.6961358019753!2d5.202051252047025!3d51.93949587960879!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47c6607a66754ecf%3A0x59d4ea4e0224dd47!2sPascalweg%2018%2C%204104%20BG%20Culemborg!5e0!3m2!1snl!2snl!4v1596636005494!5m2!1snl!2snl"
        width="100%"
        height="600px"
        frameborder="0"
        style={{ border:0 }}
        allowfullscreen=""
        aria-hidden="false"
        tabindex="0"
      />
    </Layout>
  )
}

export const pageQuery = graphql`
  query($wordpress_id: Int) {
    page: wordpressPage(wordpress_id: { eq: $wordpress_id }) {
      ...ContactPage
    }
  }
`

export default PageTemplate

const Hero = styled(ImageBackground)`
  &:before {
    background-position: bottom !important;
    background-size: cover !important;
  }
  min-height: 700px;
  margin-bottom: 300px;
  @media screen and (max-width: 992px) {
    margin-bottom: 60px;
  }
`

const Content = styled(ParseContent)`
  color: ${(props) => props.theme.color.text.light};
  text-shadow: 0 0 4px rgba(0, 0, 0, 0.85);
  font-size: ${(props) => props.theme.font.size.xm};
  font-weight: ${props => props.theme.font.weight.sm};
  line-height: ${(props) => props.theme.font.size.xxl};
`
const Heading = styled.h2`
  font-size: ${(props) => props.theme.font.size.xxxl};
  line-height: ${(props) => props.theme.font.size.xxxl};
  font-family: ${(props) => props.theme.font.family.secondary};
  color: white;
  text-transform: uppercase;
  @media screen and (max-width: 992px) {
    font-size: ${(props) => props.theme.font.size.xxl};
    line-height: ${(props) => props.theme.font.size.xxl};
  }
`

const RedLine = styled.div`
  width: calc(50% - 256px);
  height: 2px;
  border-bottom: 5px solid ${(props) => props.theme.color.face.main};
  @media screen and (max-width: 992px) {
    width: calc(50% + 96px);
  }
`
const CircleButton = styled.a`
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background: white;
  margin-right: 20px;
  display: flex;
  justify-content: center;
  padding-top: 10px;
`
const Form = styled.div`
  position: absolute;
  border: 1px solid ${(props) => props.theme.color.face.main};
  font-family: ${(props) => props.theme.font.family.main};
  font-size: ${(props) => props.theme.font.size.m};
  color: ${(props) => props.theme.color.text.main};
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.5);
  background: white;
  width: 480px;
  padding: 35px 60px 50px 60px;
  z-index: 99;
  /* @media screen and (max-width: 576px) {
    width: 100%;
  } */
  @media screen and (max-width: 992px) {
    position: relative;
    width: auto;
    padding: 20px;
  }
  h3 {
    font-family: ${(props) => props.theme.font.family.secondary};
    font-size: ${(props) => props.theme.font.size.xl};
  }
  input {
    width: 100%;
    border-radius: 7px;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.5);
    height: 52px;
    padding: 12px;
    outline: none;
    border: none;
  }
  textarea {
    width: 100%;
    padding: 12px;
    border-radius: 7px;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.5);
    height: 126px;
    outline: none;
    border: none;
  }
`
